<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-21 09:02:32
-->
<template>
  <div>
    <div class="platform">
      <div class="platform-banner">
        <img
          :src="image"
        />
      </div>
      <div class="platform-artisan-gohome">
        <div class="artisan-left">
          <div class="title">关于能匠到家</div>
          <div class="desc">
            {{ data.introduceInfo }}
          </div>
        </div>
        <div class="artisan-right">
          <!-- $utils.picturePrefix(data.introduceImage) -->
          <img
            :src="
              data.introduceImage
                ? $utils.picturePrefix(data.introduceImage)
                : ''
            "
          />
        </div>
      </div>
      <div class="platform-user-guide">
        <div class="platform-title">
          <div class="title-top">
            <img src="../../assets/img/home/img-home-title-left.png" />
            <div class="text">数据总览</div>
            <img src="../../assets/img/home/img-home-title-right.png" />
          </div>
          <div class="title-bootom">
            <img src="../../assets/img/home/img-home-title-bootom.png" />
          </div>
        </div>
        <div class="user-guide-list">
          <div
            class="user-guide-item"
            v-for="(item, i) in userGuideList"
            :key="i"
            @click="getDetail(i)"
          >
            <!-- <img :src="item.src" /> -->
            <span :class="item.icon"></span>
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <div class="platform-contact">
        <div class="platform-title">
          <div class="title-top">
            <img src="../../assets/img/home/img-home-title-left.png" />
            <div class="text">联系我们</div>
            <img src="../../assets/img/home/img-home-title-right.png" />
          </div>
          <div class="title-bootom">
            <img src="../../assets/img/home/img-home-title-bootom.png" />
          </div>
        </div>
        <div class="contact-list">
          <div class="contact-left">
            <div class="title">联系方式</div>
            <div class="desc">为您提供更好，更方便的联系我们</div>
            <img :src="$utils.picturePrefix(data.serviceProcess)" />
            <div class="phone">电话:{{ data.contactPhone }}</div>
          </div>
          <div class="contact-right">
              <div id="container" ></div>
              <div class="contento">
                  <div class="content">
                      <div class="content-top">
                          <div class="tit">能匠到家</div>
                          <a href="https://www.amap.com/search?query=%E5%AE%89%E5%BE%BD%E7%9C%81%E5%90%88%E8%82%A5%E5%B8%82%E5%BA%90%E9%98%B3%E5%8C%BA%E5%B7%A5%E4%B8%9A%E5%9B%AD%E7%A7%80%E6%B0%B4%E8%B7%AF6%E5%8F%B7&city=340100&geoobj=117.264901%7C31.937972%7C117.272183%7C31.941661&zoom=17.5"  target="_blank">去这里</a>
                      </div>
                      <div class="phone">电话:{{ data.contactPhone }}</div>
                      <div class="dizhi">地址:安徽省合肥市庐阳区工业园秀水路6号</div>
                      <div class="sanjiao"></div>
                  </div>
              </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
export default {
  data() {
    return {
      aMap: null,
      userGuideList: [
        {
          icon: "iconfont icon-zhucefujia",
          title: "注册流程",
          desc: "注册流程，让您轻松接单和购买商品，注册后成为城市合伙人。",
        },
        {
          icon: "iconfont icon-duoduanlianjie-tianchong",
          title: "多端登录流程",
          desc: "多端登录流程，为了您更好的体验，让您轻松快速了解我们的网站。",
        },
        {
          icon: "iconfont icon-liucheng",
          title: "接单流程",
          desc: "接单流程，让您了解如何轻松接单，快速完成手中订单。",
        },
        {
          icon: "iconfont icon-yijiesuanjilu",
          title: "提现流程",
          desc: "提现流程，让您轻松了解如何提现，轻松快速到账。",
        },
        {
          icon: "iconfont icon-shouyi",
          title: "收益说明",
          desc: "收益说明，让您了解如何获得收入，轻松无压力。",
        },
        {
          icon: "iconfont icon-yidongduan",
          title: "移动端门户网站",
          desc: "移动端门户网站，是一个可以快速了解我们的网站。",
        },
      ],
      banners: [],
      image:'',
      data: {},
    };
  },
  mounted() {
    this.initMap();
    this.getBanner();

  },
  destroyed() {
    this.aMap.destroy();
    this.aMap = null;
  },
  methods: {
    bannerHeight() {
        // let width = window.innerWidth;
        // let getClass = document.getElementsByClassName("platform-banner")[0];

        // // eslint-disable-next-line no-self-assign
        // width < 1200 ? (width = 1200) : (width = width);
        // let height = width / 1920 * 360;
        // console.log(getClass,height);
        // return getClass.style.height = height + "px";
    },
    getBanner() {
      this.$api.getBanner({ type: 6 }).then(res => {
        this.banners = res.data;
        setTimeout(() => {
          // this.bannerHeight();
          this.image = this.$utils.picturePrefix(this.banners[0].image)
        }, 0);
      });
    },
    initMap() {
      let map = new AMap.Map("container", {
        zoom: 17, //级别
        zooms: [3, 18],
        center: [117.264901, 31.937972], //中心点坐标
        viewMode: "3D", //使用3D视图
        size: new AMap.Size(24, 24),
        scrollWheel:false,
        dragEnable:false,
        doubleClickZoom: false
      });
      let marker = new AMap.Marker({
					position: [117.264901, 31.937972], //位置
            });
            marker.on("click", function () {
                window.open(
                    `https://www.amap.com/search?query=%E5%AE%89%E5%BE%BD%E7%9C%81%E5%90%88%E8%82%A5%E5%B8%82%E5%BA%90%E9%98%B3%E5%8C%BA%E5%B7%A5%E4%B8%9A%E5%9B%AD%E7%A7%80%E6%B0%B4%E8%B7%AF6%E5%8F%B7&city=340100&geoobj=117.264901%7C31.937972%7C117.272183%7C31.941661&zoom=17.5`
                );
                // infoWindow.open(map, marker.getPosition());
            });
			map.add(marker); //添加到地图
      this.aMap = map;
      this.getIntroduce();
    },
    getIntroduce() {
      this.$api
        .getIntroduce()
        .then(res => {
          this.data = res.data;
          setTimeout(() => {
            let map = this.aMap;
            AMap.service("AMap.PlaceSearch", function () {
              //回调函数
              //实例化PlaceSearch
              let placeSearch = new AMap.PlaceSearch({
                pageSize:1, //每页显示多少行
                pageIndex: 1, //显示的下标从那个开始
                type: "商务住宅;产业园区;产业园区",
                city: "0551", //城市
                citylimit: true, //是否强制限制在设置的城市内搜索
                map: map, //显示地图
                panel: "result", //服务显示的面板
              });
              console.log("placeSearch==",placeSearch);
              //TODO: 使用placeSearch对象调用关键字搜索的功能
              placeSearch.search(res.data.address, function (status, result) {
                if (status == "complete" && result.info == "OK") {
                  let markerList = [];
                  let pois = result.poiList.pois;
                  for (let i = 0; i < pois.length; i++) {
                    const element = pois[i];
                    if (i == 0) {
                      map.setZoomAndCenter(14, [
                        element.location.lng,
                        element.location.lat,
                      ]);
                    }
                    let marker = new AMap.Marker({
                      position: new AMap.LngLat(
                        element.location.lng,
                        element.location.lat
                      ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                      title: element.name,
                    });
                    markerList.push(marker);
                  }
                  map.add(markerList);
                }
              });
            });
          }, 0);
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally();
    },
    getDetail(type) {
      // this.$router.push({
      //   path: "platform/details",
      //   query: { type: Number(type) + 1 }
      // });
      let routeData = this.$router.resolve({
        path: `/platform/details?type=${Number(type) + 1}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
